import { useEffect, useState } from "react";
import axios from "axios";
import { PublicKey, Transaction } from "@solana/web3.js";
import { resolveToWalletAddress, getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import PhantomIcon from "../images/phantom.png";
import { setUserPhantomInfo } from "../services/ParseService";
import { notify } from "react-notify-toast";

const web3 = require("@solana/web3.js");

type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod = "connect" | "disconnect" | "signTransaction" | "signAllTransactions" | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}

interface PhantomProvider {
  publicKey: PublicKey | null;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (message: Uint8Array | string, display?: DisplayEncoding) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

function WalletConnection(props: any) {
  const [provider, setProvider] = useState<PhantomProvider | undefined>(undefined);
  const [walletKey, setWalletKey] = useState<PhantomProvider | undefined>(undefined);
  const [grumpyBalance, setGrumpyBalance] = useState<Number | undefined>(undefined);
  /**
   * @description gets Phantom provider, if it exists
   */
  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      // @ts-ignore
      const provider = window.solana as any;
      if (provider.isPhantom) return provider as PhantomProvider;
    }
  };

  /**
   * @description prompts user to connect wallet if it exists
   */
  const connectWallet = async () => {
    // @ts-ignore
    const { solana } = window;

    if (solana) {
      try {
        props.walletConnected(undefined);

        const response = await solana.connect();
        console.log("wallet account ", response.publicKey.toString());
        setWalletKey(response.publicKey.toString());

        //var fromWallet = new web3.PublicKey("2ojv9BAiHUrvsm9gxDe7fJSzbNZSJcxZvf8dqmWGHG8S");

        const connection = new web3.Connection("https://solana-api.projectserum.com/");
        const mint = new web3.PublicKey("GUdpDLrmJYiXCPmw7U65jz9acHy1z9b1CKnYJxf8kZns");
        const programId = new web3.PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL");
        const tokenAccountInfo = await connection.getParsedTokenAccountsByOwner(response.publicKey, { mint, programId });
        if (tokenAccountInfo?.value[0]?.account) {
          setGrumpyBalance(tokenAccountInfo.value[0].account.data.parsed.info.tokenAmount.uiAmountString);
        } else {
          setGrumpyBalance(0);
        }

        const solanaBalance = await connection.getBalance(response.publicKey);
        const allNfts = await getNftTokenData(response.publicKey.toString());

        // console.log(tokenAccountInfo);
        // console.log(allNfts);
        const savedInfo = await setUserPhantomInfo(response.publicKey.toString(), tokenAccountInfo, solanaBalance, allNfts);

        props.walletConnected(savedInfo);
        notify.show("Wallet connection success!", "success");
      } catch (err) {
        throw err;
        // { code: 4001, message: 'User rejected the request.' }
      }
    }
  };

  /**
   * @description disconnect Phantom wallet
   */
  const disconnectWallet = async () => {
    // @ts-ignore
    const { solana } = window;

    if (walletKey && solana) {
      await (solana as PhantomProvider).disconnect();
      setWalletKey(undefined);
      setGrumpyBalance(undefined);
    }
  };

  // detect phantom provider exists
  useEffect(() => {
    const provider = getProvider();

    if (provider) setProvider(provider);
    else setProvider(undefined);
  }, []);

  //get NFT
  const getAllNftData = async (publicKey: any) => {
    try {
      let nftTestWalletString = publicKey;

      const publicAddress = await resolveToWalletAddress({
        text: nftTestWalletString,
      });
      //Test Wallet
      const nftArray = await getParsedNftAccountsByOwner({
        publicAddress,
      });
      //Real Phantom Wallte
      // const nftArray = await getParsedNftAccountsByOwner({
      //   publicAddress: response.publicKey,
      // });

      return nftArray;
    } catch (error) {
      console.log(error);
    }
  };

  //Function to get all nft data
  const getNftTokenData = async (publicKey: any) => {
    try {
      let nftData = await getAllNftData(publicKey);
      var data = Object.keys(nftData as any).map((key) => nftData?.[key]);
      let arr = [];
      let n = data.length;
      for (let i = 0; i < n; i++) {
        console.log(data[i].data.uri);
        let val = await axios.get(data[i].data.uri);
        arr.push(val);
      }
      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="wallet-connect-area">
      {provider && !walletKey && <img src={PhantomIcon} alt="phantom" className="phantom-icon" style={{ width: "40px", cursor: "pointer" }} onClick={connectWallet} />}

      {provider && walletKey && (
        <div className="wallet-connected-area">
          <img src={PhantomIcon} alt="phantom" className="phantom-icon" style={{ width: "40px", cursor: "pointer" }} onClick={disconnectWallet} />
        </div>
      )}
      {provider && walletKey && <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-start"></div>}
      {!provider && <div className="wallet-not-connected-area"></div>}
    </div>
  );
}

export default WalletConnection;
