import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import errorIcon from "../images/error-icon.svg";
import tryButton from "../images/try-again-button.svg";

export function ErrorModal(props) {
  const { showModal, errorText, redirectHome } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    props.parentCallback();
  };
  const handleShow = () => setShow(true);
  const redirectToHomepage = () => navigate("/");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (showModal) {
      handleShow();
    } else {
      handleClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const modalStyle = {
    modalBody: {
      background: "#16182C",
      backdropFilter: "blur(20px)",
      height: "520px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      container: {
        background: "linear-gradient(130deg, rgba(253, 0, 58, 0.5) -25.21%, rgba(253, 0, 58, 0) 28.27%)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      errorHead: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "34px",
        color: "#FFFFFF",
      },
      errorText: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
      },
    },
  };

  return (
    <>
      <Modal show={show} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
        <Modal.Body className="show-grid" style={modalStyle.modalBody}>
          <Container style={modalStyle.modalBody.container}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={12}>
                <img src={errorIcon} alt="Grumpy" className="grumpy-logo" />
              </Col>
            </Row>
            <Row style={{ marginBottom: "24px" }}>
              <Col xs={12}>
                <h3 className="error-text" style={modalStyle.modalBody.errorHead}>
                  OPPS!
                </h3>
              </Col>
            </Row>
            <Row style={{ marginBottom: "48px" }}>
              <Col xs={12}>
                <span className="error-text" style={modalStyle.modalBody.errorText}>
                  {errorText}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {!redirectHome && <img src={tryButton} alt="play-game" style={{ cursor: "pointer" }} onClick={handleClose}></img>}
                {redirectHome && <img src={tryButton} alt="play-game" style={{ cursor: "pointer" }} onClick={redirectToHomepage}></img>}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
