import { useState, useEffect } from "react";
import WalletCoinIcon from "../images/coin-icon.svg";
// import PriceIcon from "../images/price-icon.svg";
// import AddCoinIcon from "../images/add-coin.svg";
import SwordBattleIcon from "../images/sword-battle.svg";
import Carousel from "react-bootstrap/Carousel";
import SolanaIcon from "../images/solana-icon.png";

const footerStyles = {
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "80px",
    backgroundColor: "rgb(42, 37, 50)",
    paddingRight: "70px",
    paddingLeft: "70px",
    position: "fixed",
    bottom: "0px",
    zIndex: "2",
    background: "rgba(3, 5, 27, 0.3)",
    backdropFilter: "blur(10px)",
    leftSide: {
      display: "flex",
      alignItems: "center",
      width: "33%",
      nftCircle: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        objectFit: "cover",
      },
      userInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginLeft: "12px",
        textStyle: {
          fontFamily: "'Ubuntu'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "center",
          color: "#FFFFFF",
        },
      },
    },
    middleSide: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "33%",
      nftImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "8px",
      },
      swordIcon: {
        position: "absolute",
        top: "5px",
        marginLeft: "7.5rem",
        zIndex: "20",
      },
    },
    rightSide: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "33%",
      grumpyToken: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "1rem",
        grumpyTokenText: {
          text: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#B3B4BB",
            marginLeft: "8px",
          },
          value: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: "8px",
          },
          warTimer: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "24px",
            color: "#FFFFFF",
            width: "150px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "8px",
            timerText: {
              marginRight: "4px",
            },
            prizeButton: {
              width: "70%",
              height: "100%",
              backgroundColor: "rgb(239 151 43)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            },
          },
        },
      },

      walletIcon: {
        width: "28px",
        height: "28px",
        objectFit: "cover",
      },
      userInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginLeft: "12px",
        textStyle: {
          fontSize: "15px",
          color: "#ffffff",
          marginTop: "8px",
        },
      },
    },
  },
};

const calculateTimeLeft = (endDate) => {
  let difference = +new Date(endDate) - +new Date();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const GrumpyFooter = (props) => {
  const [index, setIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props?.myWar?.battleground.end_date.iso));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(props?.myWar?.battleground.end_date.iso));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.warTimer.timerText}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    props.setSelectedIndex(selectedIndex);
  };

  const getRewardsClick = () => {
    props.rewardsClick();
  };

  return (
    <div style={footerStyles.footerContainer}>
      <div style={footerStyles.footerContainer.leftSide}>
        <img src={props.myCat} style={footerStyles.footerContainer.leftSide.nftCircle} alt="nft-circle" />
        <div style={footerStyles.footerContainer.leftSide.userInfo}>
          <div style={footerStyles.footerContainer.leftSide.userInfo.textStyle}>
            <span>{props.myUser}</span>
          </div>
        </div>
      </div>
      <div style={footerStyles.footerContainer.middleSide}>
        {/* <img src={props.myCat} style={footerStyles.footerContainer.middleSide.nftImage} alt="nft-grumpy" /> */}
        <Carousel fade interval={null} activeIndex={index} onSelect={handleSelect} style={{ width: "150px" }}>
          {props?.myCats.map((cat, index) => (
            <Carousel.Item key={index} style={{ width: "150px", height: "150px", marginBottom: "5rem" }}>
              <img className="d-block" src={cat?.attributes.image._url} style={footerStyles.footerContainer.middleSide.nftImage} alt={"my-cat" + index} />
              {cat?.attributes.staked && <img src={SwordBattleIcon} style={footerStyles.footerContainer.middleSide.swordIcon} alt="battle-sword"></img>}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div style={footerStyles.footerContainer.rightSide}>
        <div style={footerStyles.footerContainer.rightSide.grumpyToken}>
          <img src={SolanaIcon} style={footerStyles.footerContainer.rightSide.walletIcon} alt="wallet-icon" />
          <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText}>
            <span style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.text}>Solana</span>
            <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.value}>
              <span>{props?.wallets?.[1]?.attributes?.amount}</span>
            </div>
          </div>
        </div>
        <div style={footerStyles.footerContainer.rightSide.grumpyToken}>
          <img src={WalletCoinIcon} style={footerStyles.footerContainer.rightSide.walletIcon} alt="wallet-icon" />
          <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText}>
            <span style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.text}>Grumpy Token</span>
            <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.value}>
              <span>{props?.wallets?.[0]?.attributes?.amount}</span>
              {/* <img src={AddCoinIcon} width="16" alt="coin-add-grumpy" /> */}
            </div>
          </div>
        </div>
        <div style={footerStyles.footerContainer.rightSide.grumpyToken}>
          {/* <img src={PriceIcon} style={footerStyles.footerContainer.rightSide.walletIcon} alt="price-icon" /> */}
          <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText}>
            <span style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.text}>Time To End Of War</span>
            {props?.myWar?.battleground && (
              <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.warTimer}>
                {timerComponents.length ? (
                  timerComponents
                ) : (
                  <span style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.warTimer.prizeButton} onClick={getRewardsClick}>
                    Show Rewards
                  </span>
                )}
              </div>
            )}
            {!props?.myWar?.battleground && (
              <div style={footerStyles.footerContainer.rightSide.grumpyToken.grumpyTokenText.warTimer}>Choose your region to start to war !</div>
            )}
          </div>
        </div>
        {/* <img src={WalletCoinIcon} style={footerStyles.footerContainer.rightSide.walletIcon} alt="nft-wallet" />
          <div style={footerStyles.footerContainer.rightSide.userInfo}>
            <div style={footerStyles.footerContainer.rightSide.userInfo.textStyle}>
              <span>GRUMPY TOKEN : 255</span>
            </div>
            <div style={footerStyles.footerContainer.rightSide.userInfo.textStyle}>
              <span>Current Market Price : $1</span>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default GrumpyFooter;
