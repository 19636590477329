/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import GrumpyHeader from "../components/MapHeader";
import GrumpyFooter from "../components/MapFooter";
import Loading from "../components/Loading";
import { getBattleGround, getBattleGroundStatistic, getCats, fetchDiscordUser, simulateSpot, joinBattleGround, getRewardsInfo } from "../services/ParseService";
import { Table, Modal, Container, Row, Col, ProgressBar } from "react-bootstrap";
import Lottie from "react-lottie";
import grumpyLogoText from "../images/gr-home.svg";

import volcanoCard from "../images/Card/volcano.svg";
import iceCard from "../images/Card/ice.svg";
import sandCard from "../images/Card/desert.svg";
import cyberCard from "../images/Card/cyber.svg";
import jungleCard from "../images/Card/jungle.svg";
import wastelandCard from "../images/Card/wasteland.svg";

import postProd from "../images/post-prod.png";
import joinBattle from "../images/join-battle.svg";
import joinBattleDisabled from "../images/join-battle-disabled.svg";
import cancelButton from "../images/cancel-button.svg";

import powerIcon from "../images/power-icon.png";
import rewardIcon from "../images/reward-icon.png";
import totalPowerBonusIcon from "../images/total-power-bonus.png";
// import totalRewardBonusIcon from "../images/total-reward-bonus.png";

import simulateIceIcon from "../images/simulate-ice-icon.png";
import simulateJungleIcon from "../images/simulate-jungle-icon.png";
import simulateCyberIcon from "../images/simulate-cyber-icon.png";
import simulateVolcanoIcon from "../images/simulate-volcano-icon.png";
import simulateDesertIcon from "../images/simulate-desert-icon.png";
import simulateWastelandIcon from "../images/simulate-wasteland-icon.png";
import catLottie from "../images/cat-lottie.json";
import SolanaIcon from "../images/solana-icon.png";
import WalletCoinIcon from "../images/coin-icon.svg";

import { ErrorModal } from "../components/ErrorModal";
import { WeeklyRewardsModal } from "../components/WeeklyRewardModal";
import { useNavigate } from "react-router-dom";

const grumpyMapStyles = {
  mapContainer: {
    zIndex: "1",
    position: "absolute",
    marginTop: "11rem",
    width: "100%",
    height: "100%",
    mapRowElement: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      zoom: "1.2",
      // marginBottom: "-4px",
      mapColumnElement: {
        cursor: "pointer",
        mapSplittedImg: {
          width: "62px",
        },
        myStackedNft: {
          position: "absolute",
          nftCircle: {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            objectFit: "cover",
          },
          stackedPower: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            left: "23px",
            top: "20px",
            background: "#1671F9",
            border: "1px solid #1671F9",
            borderRadius: "50%",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontSize: "10px",
            position: "absolute",
          },
        },
      },
    },
  },
  statisticModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    backgroundColor: "rgb(9 7 7 / 70%)",
    zIndex: "9999",
    animation: "fadein 1.5s",
    MozAnimation: "fadein 1.5s",
    WebkitAnimation: "fadein 1.5s",
    OAnimation: "fadein 1.5s",
    modalContainer: {
      width: "500px",
      padding: "30px",
      background: "#2c3035",
    },
  },
};

const modalStyle = {
  modalHeader: {
    background: "#1C1E32",
    borderRadius: "8px 8px 0px 0px; !important",
    borderBottom: "none",
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  modalBody: {
    background: "#16182C",
    backdropFilter: "blur(20px)",
  },
  tableStyle: {
    table: {
      th: {
        width: "140px",
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#FFFFFF",
        background: "rgba(255, 255, 255, 0.08)",
        textAlign: "center",
        padding: "20px",
        // display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        // justifyContent: "center",
        // height: "90px",
      },
      thClan: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "18px",
        color: "#B3B4BB",
        background: "rgba(255, 255, 255, 0.08)",
        textAlign: "center",
        cursor: "pointer",
        text: {
          marginTop: "4px",
          marginBottom: "0",
        },
      },
      td: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#FFFFFF",
        textAlign: "center",
      },
    },
  },
};

const simulateModalStyle = {
  modalHeader: {
    background: "#1C1E32",
    borderRadius: "8px 8px 0px 0px; !important",
    borderBottom: "none",
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  modalBody: {
    background: "#16182C",
    backdropFilter: "blur(20px)",
    simulateIcon: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      simulateVolcanoBg: {
        background: "linear-gradient(180deg, #D8540D -31.21%, rgba(216, 84, 13, 0) 25.27%)",
        paddingTop: "35px",
        borderRadius: "10px",
      },
      simulateIceBg: {
        background: "linear-gradient(180deg, #FFFFFF -31.21%, rgba(22, 113, 249, 0) 25.27%)",
        paddingTop: "35px",
        borderRadius: "10px",
      },
      simulateCyberBg: {
        background: "linear-gradient(180deg, #6A3692 -31.21%, rgba(106, 54, 146, 0) 25.27%)",
        paddingTop: "35px",
        borderRadius: "10px",
      },
      simulateDesertBg: {
        background: "linear-gradient(180deg, #FFCA8A -31.21%, rgba(255, 202, 138, 0) 25.27%)",
        paddingTop: "35px",
        borderRadius: "10px",
      },
      simulateJungleBg: {
        background: "linear-gradient(180deg, #9AFDAF -31.21%, rgba(154, 253, 175, 0) 25.27%)",
        paddingTop: "35px",
        borderRadius: "10px",
      },
      simulateWastelandBg: {
        background: "linear-gradient(180deg, #C7323F -31.21%, rgba(199, 50, 63, 0) 25.27%)",
        paddingTop: "35px",
        borderRadius: "10px",
      },
    },
    catImage: {
      width: "100%",
    },
    catClan: {
      clanText: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "23px",
        textAlign: "center",
        color: "#FFFFFF",
        position: "absolute",
        left: "75px",
        marginTop: "0.7rem",
      },
    },
    catParameters: {
      catPower: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px 5px",
        powerIcon: {},
        powerInfo: {
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          width: "100%",
          powerText: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#B3B4BB",
          },
          powerValue: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            valueSpan: {
              fontFamily: "'Ubuntu'",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#FFFFFF",
              marginLeft: "12px",
            },
            valueProgress: {
              width: "100%",
              height: "10px",
            },
          },
        },
      },
      catWeather: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px 5px",
        weatherIcon: {},
        weatherInfo: {
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          width: "100%",
          weatherText: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#B3B4BB",
          },
          weatherValue: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
          },
        },
      },
      catLand: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px 5px",
        landIcon: {},
        landInfo: {
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          width: "100%",
          landText: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#B3B4BB",
          },
          landValue: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
          },
        },
      },
      smallText: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "18px",
        color: "#B3B4BB",
      },
    },
  },
};

const splittedTemplates = {
  rowTemplate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  columnTemplate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
};

const GrumpyMap = () => {
  const [loading, setLoading] = useState(false);
  const [showLottie, setShowLottie] = useState(false);
  const [myUser, setMyUser] = useState(null);
  const [myCats, setMyCats] = useState([]);
  const [myWar, setMyWar] = useState(null);
  const [selectedCatIndex, setSelectedCatIndex] = useState(0);

  const [battleGround, setBattleGround] = useState(Object);
  const [target, setTarget] = useState(null);
  const [errorText, setErrorText] = useState("Something went wrong. Let’s try one more again");
  const [showError, setShowError] = useState(false);
  const [errorRedirect, setErrorRedirect] = useState(false);

  //Statistics side states and events
  const [statistics, setStatistics] = useState(Object);
  const [statisticModalShow, setStatisticModalShow] = useState(false);
  const handleStatisticClose = () => setStatisticModalShow(false);
  const handleStatisticShow = () => setStatisticModalShow(true);

  //Simulate spot side states and events
  const [simulateSpotObject, setSimulateSpotObject] = useState(Object);
  const [simulateSpotModalShow, setSimulateSpotModalShow] = useState(false);
  const handleSimulateSpotClose = () => setSimulateSpotModalShow(false);
  const handleSimulateSpotShow = () => setSimulateSpotModalShow(true);
  const handleSimulateSpotJoin = () => join();

  //Rewards side states and events
  const [rewards, setRewards] = useState(Object);
  const [rewardsModalShow, setRewardsModalShow] = useState(false);
  const handleRewardsClose = () => setRewardsModalShow(false);
  const handleRewardsShow = () => setRewardsModalShow(true);

  //Rewards Info side states and events
  const [rewardsInfo, setRewardsInfo] = useState(Object);
  const [rewardsInfoModalShow, setRewardsInfoModalShow] = useState(false);
  const handleRewardsInfoClose = () => setRewardsInfoModalShow(false);
  const handleRewardsInfoShow = () => setRewardsInfoModalShow(true);

  const [showWeeklyReward, setShowWeeklyReward] = useState(false);

  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      setShowLottie(true);
      const user = await fetchDiscordUser();
      setMyUser(user);

      const catInfo = await getCats();
      setMyCats(catInfo.cats);
      if (catInfo?.war) {
        setMyWar(catInfo.war.toJSON());
      }

      const battleGround = await getBattleGround();
      setBattleGround(battleGround);
      if (battleGround.code === 9001) {
        const battleGroundStatistic = await getBattleGroundStatistic(battleGround.data.id);
        setStatistics(battleGroundStatistic);
      } else {
        const battleGroundStatistic = await getBattleGroundStatistic(battleGround.objectId);
        setStatistics(battleGroundStatistic);
      }

      setTimeout(() => {
        setShowLottie(false);
      }, 3000);
    } catch (error) {
      if (error.message === "No active battle ground found.") {
        setShowLottie(false);
        setLoading(false);
      } else if (error.message === "Cat not found.") {
        setShowLottie(false);
        setLoading(false);
        navigate("/claim");
      } else {
        setShowLottie(false);
        setLoading(false);
        setErrorRedirect(true);
        setErrorText(error.message);
        setShowError(true);
      }
    }
  }, []);

  useEffect(() => {
    simulate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  const handleCloseCallback = () => {
    setShowError(false);
  };

  const handleWeeklyRewardCallback = () => {
    setShowWeeklyReward(false);
  };

  const handleSelectedCat = (index) => {
    setSelectedCatIndex(index);
  };

  const handleOpenStatistics = () => {
    if (battleGround.code !== 9001) {
      handleStatisticShow(true);
    } else {
      setErrorText("You can't see statistics of this battle ground");
      setShowError(true);
    }
  };

  const handleOpenWeeklyRewards = () => {
    setShowWeeklyReward(true);
  };

  const volcanoClick = () => {
    if (target === "Volcano") {
      handleSimulateSpotShow();
    } else {
      setTarget("Volcano");
    }
  };

  const wastelandClick = () => {
    if (target === "Wasteland") {
      handleSimulateSpotShow();
    } else {
      setTarget("Wasteland");
    }
  };

  const jungleClick = () => {
    if (target === "Jungle") {
      handleSimulateSpotShow();
    } else {
      setTarget("Jungle");
    }
  };

  const iceClick = () => {
    if (target === "Ice") {
      handleSimulateSpotShow();
    } else {
      setTarget("Ice");
    }
  };

  const cyberClick = () => {
    if (target === "Cyber") {
      handleSimulateSpotShow();
    } else {
      setTarget("Cyber");
    }
  };

  const sandClick = () => {
    if (target === "Sand") {
      handleSimulateSpotShow();
    } else {
      setTarget("Sand");
    }
  };

  const simulate = async () => {
    if (target != null) {
      let cat = myCats[selectedCatIndex].id;
      let battleground_id = battleGround.objectId;
      setLoading(true);
      simulateSpot(target, battleground_id, cat)
        .then((simulateResult) => {
          setSimulateSpotObject(simulateResult);
          handleSimulateSpotShow();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setErrorText(err.message);
          setShowError(true);
        });
    }
  };

  const join = () => {
    let cat = myCats[selectedCatIndex].id;
    let battleground_id = battleGround.objectId;
    setLoading(true);
    joinBattleGround(battleground_id, target, cat)
      .then(async (joinResult) => {
        const catInfo = await getCats();
        setMyCats(catInfo.cats);
        if (catInfo?.war) {
          setMyWar(catInfo.war.toJSON());
        }

        const battleGround = await getBattleGround();
        setBattleGround(battleGround);
        handleSimulateSpotClose();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrorText(err.message);
        setShowError(true);
      });
  };

  const handleRewardsClick = async () => {
    setLoading(true);
    if (Object.keys(rewards).length === 0) {
      const rewardsInfo = await getRewardsInfo(battleGround.data.id);
      let rewardDetails = [];
      rewardsInfo.forEach((reward) => {
        rewardDetails.push(reward.toJSON());
      });
      setRewards(rewardDetails);
    }
    setLoading(false);
    handleRewardsShow();
  };

  const handleRewardsInfoClick = async (index) => {
    const rewardInfo = rewards[index].info;
    setRewardsInfo(rewardInfo);
    handleRewardsInfoShow();
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: catLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  {
    {
      if (showLottie === false) {
        return (
          <div className="war-map">
            <GrumpyHeader weather={battleGround.weather} landStatus={battleGround.ground} clickTutorial={handleOpenStatistics} clickReward={handleOpenWeeklyRewards} />
            <img src={postProd} style={{ width: "100%", height: "100vh", position: "absolute", zIndex: "1" }} alt="post-prod-bg"></img>
            <div className="map-container" style={grumpyMapStyles.mapContainer}>
              {splittedTemplates.rowTemplate.map((rowValue) => {
                return (
                  <div className="map-row-element" key={rowValue} style={grumpyMapStyles.mapContainer.mapRowElement}>
                    {splittedTemplates.columnTemplate.map((columnValue) => {
                      //Volcano => 8-4, 9-4, 10-4, 11-4, 8-5, 9-5, 10-5, 11-5
                      //Wasteland => 5-13 , 6-13, 5-14, 6-14
                      //Jungle => 9-16 , 10-16, 11-16, 10-15, 11-15
                      //Ice => 4--8, 5-8, 6-8, 4-9, 5-9, 6-9
                      //Cyber => 13-14, 14-14, 15-14, 16-14
                      //Sand => 12-7, 13-7, 14-7, 13-8, 14-8, 15-8
                      if (
                        (rowValue === 8 && columnValue === 4) ||
                        (rowValue === 9 && columnValue === 4) ||
                        (rowValue === 10 && columnValue === 4) ||
                        (rowValue === 11 && columnValue === 4) ||
                        (rowValue === 8 && columnValue === 5) ||
                        (rowValue === 9 && columnValue === 5) ||
                        (rowValue === 10 && columnValue === 5) ||
                        (rowValue === 11 && columnValue === 5)
                      ) {
                        if (myCats[selectedCatIndex]?.attributes.staked && rowValue === 8 && columnValue === 5) {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={volcanoClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                              {myWar?.target === "Volcano" && (
                                <div className="my-stacked-nft" style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft}>
                                  <img
                                    src={myCats[selectedCatIndex]?.attributes.image._url}
                                    style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.nftCircle}
                                    alt="nft-circle"
                                  />
                                  <span style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.stackedPower}>
                                    {myCats[selectedCatIndex]?.attributes.power}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={volcanoClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            </div>
                          );
                        }
                      } else if (
                        (rowValue === 5 && columnValue === 13) ||
                        (rowValue === 6 && columnValue === 13) ||
                        (rowValue === 5 && columnValue === 14) ||
                        (rowValue === 6 && columnValue === 14)
                      ) {
                        if (myCats[selectedCatIndex]?.attributes.staked && rowValue === 5 && columnValue === 14) {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={wastelandClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                              {myWar?.target === "Wasteland" && (
                                <div className="my-stacked-nft" style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft}>
                                  <img
                                    src={myCats[selectedCatIndex]?.attributes.image._url}
                                    style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.nftCircle}
                                    alt="nft-circle"
                                  />
                                  <span style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.stackedPower}>
                                    {myCats[selectedCatIndex]?.attributes.power}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={wastelandClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            </div>
                          );
                        }
                      } else if (
                        (rowValue === 9 && columnValue === 16) ||
                        (rowValue === 10 && columnValue === 16) ||
                        (rowValue === 11 && columnValue === 16) ||
                        (rowValue === 10 && columnValue === 15) ||
                        (rowValue === 11 && columnValue === 15)
                      ) {
                        if (myCats[selectedCatIndex]?.attributes.staked && rowValue === 9 && columnValue === 16) {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={jungleClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                              {myWar?.target === "Jungle" && (
                                <div className="my-stacked-nft" style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft}>
                                  <img
                                    src={myCats[selectedCatIndex]?.attributes.image._url}
                                    style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.nftCircle}
                                    alt="nft-circle"
                                  />
                                  <span style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.stackedPower}>
                                    {myCats[selectedCatIndex]?.attributes.power}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={jungleClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            </div>
                          );
                        }
                      } else if (
                        (rowValue === 4 && columnValue === 8) ||
                        (rowValue === 5 && columnValue === 8) ||
                        (rowValue === 6 && columnValue === 8) ||
                        (rowValue === 4 && columnValue === 9) ||
                        (rowValue === 5 && columnValue === 9) ||
                        (rowValue === 6 && columnValue === 9)
                      ) {
                        if (myCats[selectedCatIndex]?.attributes.staked && rowValue === 4 && columnValue === 9) {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={iceClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                              {myWar?.target === "Ice" && (
                                <div className="my-stacked-nft" style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft}>
                                  <img
                                    src={myCats[selectedCatIndex]?.attributes.image._url}
                                    style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.nftCircle}
                                    alt="nft-circle"
                                  />
                                  <span style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.stackedPower}>
                                    {myCats[selectedCatIndex]?.attributes.power}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={iceClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            </div>
                          );
                        }
                      } else if (
                        (rowValue === 13 && columnValue === 14) ||
                        (rowValue === 14 && columnValue === 14) ||
                        (rowValue === 15 && columnValue === 14) ||
                        (rowValue === 16 && columnValue === 14)
                      ) {
                        if (myCats[selectedCatIndex]?.attributes.staked && rowValue === 15 && columnValue === 14) {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={cyberClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                              {myWar?.target === "Cyber" && (
                                <div className="my-stacked-nft" style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft}>
                                  <img
                                    src={myCats[selectedCatIndex]?.attributes.image._url}
                                    style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.nftCircle}
                                    alt="nft-circle"
                                  />
                                  <span style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.stackedPower}>
                                    {myCats[selectedCatIndex]?.attributes.power}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={cyberClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            </div>
                          );
                        }
                      } else if (
                        (rowValue === 12 && columnValue === 7) ||
                        (rowValue === 13 && columnValue === 7) ||
                        (rowValue === 14 && columnValue === 7) ||
                        (rowValue === 13 && columnValue === 8) ||
                        (rowValue === 14 && columnValue === 8) ||
                        (rowValue === 15 && columnValue === 8)
                      ) {
                        if (myCats[selectedCatIndex]?.attributes.staked && rowValue === 14 && columnValue === 8) {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={sandClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                              {myWar?.target === "Sand" && (
                                <div className="my-stacked-nft" style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft}>
                                  <img
                                    src={myCats[selectedCatIndex]?.attributes.image._url}
                                    style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.nftCircle}
                                    alt="nft-circle"
                                  />
                                  <span style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.myStackedNft.stackedPower}>
                                    {myCats[selectedCatIndex]?.attributes.power}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="map-column-element" key={columnValue} style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement} onClick={sandClick}>
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            </div>
                          );
                        }
                      } else {
                        return (
                          <div className="map-column-element" key={columnValue}>
                            {
                              <img
                                src={"/grumpy-splitted-map/row-" + rowValue + "-column-" + columnValue + ".png"}
                                style={grumpyMapStyles.mapContainer.mapRowElement.mapColumnElement.mapSplittedImg}
                                className="map-splitted-image"
                                alt={rowValue + " " + columnValue}
                              />
                            }
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>

            <GrumpyFooter
              myUser={myUser?.guildDetail.user.username}
              myCats={myCats}
              myCat={myCats[0]?.attributes.image._url}
              wallets={myUser?.wallets}
              myWar={myWar}
              setSelectedIndex={handleSelectedCat}
              rewardsClick={handleRewardsClick}
            />
            {loading && <Loading />}

            <Modal show={statisticModalShow} onHide={handleStatisticClose} size="lg" centered>
              <Modal.Header closeButton style={modalStyle.modalHeader} closeVariant="white">
                <Modal.Title>Clan Statistics</Modal.Title>
              </Modal.Header>
              <Modal.Body style={modalStyle.modalBody}>
                <Table bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th style={modalStyle.tableStyle.table.th}>
                        <img src={grumpyLogoText} alt="grumpy-logo-modal" width="100"></img>
                      </th>
                      <th style={modalStyle.tableStyle.table.thClan}>
                        <img src={volcanoCard} alt="clan-logo-modal" width="50"></img>
                        <p style={modalStyle.tableStyle.table.thClan.text}>Volcano</p>
                      </th>
                      <th style={modalStyle.tableStyle.table.thClan}>
                        <img src={iceCard} alt="clan-logo-modal" width="50"></img>
                        <p style={modalStyle.tableStyle.table.thClan.text}>Ice</p>
                      </th>
                      <th style={modalStyle.tableStyle.table.thClan}>
                        <img src={sandCard} alt="clan-logo-modal" width="50"></img>
                        <p style={modalStyle.tableStyle.table.thClan.text}>Sand</p>
                      </th>
                      <th style={modalStyle.tableStyle.table.thClan}>
                        <img src={cyberCard} alt="clan-logo-modal" width="50"></img>
                        <p style={modalStyle.tableStyle.table.thClan.text}>Cyber</p>
                      </th>
                      <th style={modalStyle.tableStyle.table.thClan}>
                        <img src={jungleCard} alt="clan-logo-modal" width="50"></img>
                        <p style={modalStyle.tableStyle.table.thClan.text}>Jungle</p>
                      </th>
                      <th style={modalStyle.tableStyle.table.thClan}>
                        <img src={wastelandCard} alt="clan-logo-modal" width="50"></img>
                        <p style={modalStyle.tableStyle.table.thClan.text}>Wasteland</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={modalStyle.tableStyle.table.th}>Total Point</th>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Volcano?.total_point.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Ice?.total_point.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Sand?.total_point.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Cyber?.total_point.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Jungle?.total_point.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Wasteland?.total_point.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <th style={modalStyle.tableStyle.table.th}>Total Reward Bonus</th>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Volcano?.totalRewardBonus.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Ice?.totalRewardBonus.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Sand?.totalRewardBonus.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Cyber?.totalRewardBonus.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Jungle?.totalRewardBonus.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Wasteland?.totalRewardBonus.toFixed(2)}</td>
                    </tr> */}
                    {/* <tr>
                      <th style={modalStyle.tableStyle.table.th}>Total Reward</th>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Volcano?.total_reward.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Ice?.total_reward.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Sand?.total_reward.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Cyber?.total_reward.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Jungle?.total_reward.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Wasteland?.total_reward.toFixed(2)}</td>
                    </tr> */}
                    <tr>
                      <th style={modalStyle.tableStyle.table.th}>Total Power</th>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Volcano?.total_power.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Ice?.total_power.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Sand?.total_power.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Cyber?.total_power.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Jungle?.total_power.toFixed(2)}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Wasteland?.total_power.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th style={modalStyle.tableStyle.table.th}>Total Cat</th>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Volcano?.total_cat}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Ice?.total_cat}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Sand?.total_cat}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Cyber?.total_cat}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Jungle?.total_cat}</td>
                      <td style={modalStyle.tableStyle.table.td}>{statistics?.Wasteland?.total_cat}</td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>

            <Modal show={simulateSpotModalShow} onHide={handleSimulateSpotClose} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
              <Modal.Header style={simulateModalStyle.modalHeader}>
                <Modal.Title style={{ fontSize: "16px" }}>SIMULATE SPOT INFO FOR JOIN BATTLE</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid" style={simulateModalStyle.modalBody}>
                <Container>
                  <Row>
                    <Col xs={8} md={6} style={simulateModalStyle.modalBody.simulateIcon}>
                      {target === "Volcano" && <img src={simulateVolcanoIcon} alt="clan-logo-modal" style={simulateModalStyle.modalBody.simulateIcon.simulateVolcanoBg}></img>}
                      {target === "Ice" && <img src={simulateIceIcon} alt="clan-logo-modal" style={simulateModalStyle.modalBody.simulateIcon.simulateIceBg}></img>}
                      {target === "Sand" && <img src={simulateDesertIcon} alt="clan-logo-modal" style={simulateModalStyle.modalBody.simulateIcon.simulateDesertBg}></img>}
                      {target === "Cyber" && <img src={simulateCyberIcon} alt="clan-logo-modal" style={simulateModalStyle.modalBody.simulateIcon.simulateCyberBg}></img>}
                      {target === "Jungle" && <img src={simulateJungleIcon} alt="clan-logo-modal" style={simulateModalStyle.modalBody.simulateIcon.simulateJungleBg}></img>}
                      {target === "Wasteland" && (
                        <img src={simulateWastelandIcon} alt="clan-logo-modal" style={simulateModalStyle.modalBody.simulateIcon.simulateWastelandBg}></img>
                      )}
                    </Col>
                    <Col xs={10} md={6}>
                      <div className="cat-parameters" style={simulateModalStyle.modalBody.catParameters}>
                        <div className="cat-power" style={simulateModalStyle.modalBody.catParameters.catPower}>
                          <div className="power-icon" style={simulateModalStyle.modalBody.catParameters.catPower.powerIcon}>
                            <img src={powerIcon} alt="power-icon" />
                          </div>
                          <div className="power-info" style={simulateModalStyle.modalBody.catParameters.catPower.powerInfo}>
                            <span className="power-info-text" style={simulateModalStyle.modalBody.catParameters.catPower.powerInfo.powerText}>
                              Your Cat Power
                            </span>
                            <span className="power-info-value" style={simulateModalStyle.modalBody.catParameters.catPower.powerInfo.powerValue}>
                              <ProgressBar
                                now={simulateSpotObject?.cat_power}
                                min={0}
                                max={20}
                                style={simulateModalStyle.modalBody.catParameters.catPower.powerInfo.powerValue.valueProgress}
                              />

                              <span className="value" style={simulateModalStyle.modalBody.catParameters.catPower.powerInfo.powerValue.valueSpan}>
                                {simulateSpotObject?.cat_power}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="cat-weather" style={simulateModalStyle.modalBody.catParameters.catWeather}>
                          <div className="weather-icon" style={simulateModalStyle.modalBody.catParameters.catWeather.weatherIcon}>
                            <img src={rewardIcon} alt="weather-icon" />
                          </div>
                          <div className="weather-info" style={simulateModalStyle.modalBody.catParameters.catWeather.weatherInfo}>
                            <span className="weather-info-text" style={simulateModalStyle.modalBody.catParameters.catWeather.weatherInfo.weatherText}>
                              Reward
                            </span>
                            <div>
                              <span className="weather-info-value" style={simulateModalStyle.modalBody.catParameters.catWeather.weatherInfo.weatherValue}>
                                <img src={SolanaIcon} style={{ width: "28px", height: "28px", objectFit: "cover" }} alt="wallet-icon" />
                                {simulateSpotObject?.sol_reward}
                              </span>
                              &nbsp;
                              <span className="weather-info-value" style={simulateModalStyle.modalBody.catParameters.catWeather.weatherInfo.weatherValue}>
                                <img src={WalletCoinIcon} style={{ width: "24px", height: "24px", objectFit: "cover" }} alt="wallet-icon" />
                                {simulateSpotObject?.reward}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="cat-land" style={simulateModalStyle.modalBody.catParameters.catLand}>
                          <div className="land-icon" style={simulateModalStyle.modalBody.catParameters.catLand.landIcon}>
                            <img src={totalPowerBonusIcon} alt="land-icon" />
                          </div>
                          <div className="land-info" style={simulateModalStyle.modalBody.catParameters.catLand.landInfo}>
                            <span className="land-info-text" style={simulateModalStyle.modalBody.catParameters.catLand.landInfo.landText}>
                              Total Power Bonus
                            </span>
                            <span className="land-info-value" style={simulateModalStyle.modalBody.catParameters.catLand.landInfo.landValue}>
                              <span style={{ color: simulateSpotObject?.totalPowerBonus > simulateSpotObject?.cat_power ? "#53BF9D" : "#F94C66" }}>
                                {simulateSpotObject?.totalPowerBonus}
                                &nbsp;
                                <span>{simulateSpotObject?.totalPowerBonus > simulateSpotObject?.cat_power ? "↑" : "↓"}</span>
                              </span>
                            </span>
                          </div>
                        </div>
                        {/* <div className="cat-land" style={simulateModalStyle.modalBody.catParameters.catLand}>
                          <div className="land-icon" style={simulateModalStyle.modalBody.catParameters.catLand.landIcon}>
                            <img src={totalRewardBonusIcon} alt="land-icon" />
                          </div>
                          <div className="land-info" style={simulateModalStyle.modalBody.catParameters.catLand.landInfo}>
                            <span className="land-info-text" style={simulateModalStyle.modalBody.catParameters.catLand.landInfo.landText}>
                              Total Reward Bonus
                            </span>
                            <span className="land-info-value" style={simulateModalStyle.modalBody.catParameters.catLand.landInfo.landValue}>
                              {simulateSpotObject?.totalPowerBonus}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Col xs={6} md={6} style={{ marginTop: "2rem", padding: "0" }}>
                      <img src={cancelButton} alt="play-game" style={{ width: "100%", cursor: "pointer" }} onClick={handleSimulateSpotClose}></img>
                    </Col>
                    <Col xs={6} md={6} style={{ marginTop: "2rem", padding: "0" }}>
                      {/* if simulateSpotObject.canJoin true */}
                      {simulateSpotObject.canJoin && (
                        <img src={joinBattle} alt="play-game" style={{ width: "115%", cursor: "pointer" }} onClick={handleSimulateSpotJoin}></img>
                      )}
                      {/* if simulateSpotObject.canJoin false */}
                      {!simulateSpotObject.canJoin && <img src={joinBattleDisabled} alt="play-game" style={{ width: "115%" }}></img>}
                    </Col>
                  </Row>
                  {simulateSpot.canJoin && (
                    <Row>
                      <Col xs={12} md={12} style={{ marginTop: "16px" }}>
                        <small style={simulateModalStyle.modalBody.catParameters.smallText}>You've joined this battleground.</small>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Modal.Body>
            </Modal>

            <Modal show={rewardsModalShow} onHide={handleRewardsClose} size="lg" centered>
              <Modal.Header closeButton style={modalStyle.modalHeader} closeVariant="white">
                <Modal.Title>Rewards Info</Modal.Title>
              </Modal.Header>

              <Modal.Body style={modalStyle.modalBody}>
                <Table bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th style={modalStyle.tableStyle.table.th}>
                        <img src={grumpyLogoText} alt="grumpy-logo-modal" width="100"></img>
                      </th>
                      {rewards.length > 0 &&
                        rewards.map((reward, index) => (
                          <th style={modalStyle.tableStyle.table.thClan} onClick={() => handleRewardsInfoClick(index)}>
                            {reward.target === "Volcano" && <img src={volcanoCard} alt="volcano-logo-modal" width="100"></img>}
                            {reward.target === "Ice" && <img src={iceCard} alt="ice-logo-modal" width="100"></img>}
                            {reward.target === "Sand" && <img src={sandCard} alt="sand-logo-modal" width="100"></img>}
                            {reward.target === "Cyber" && <img src={cyberCard} alt="cyber-logo-modal" width="100"></img>}
                            {reward.target === "Jungle" && <img src={jungleCard} alt="jungle-logo-modal" width="100"></img>}
                            {reward.target === "Wasteland" && <img src={wastelandCard} alt="wasteland-logo-modal" width="100"></img>}

                            {reward.target === "Volcano" && <p style={modalStyle.tableStyle.table.thClan.text}>Volcano</p>}
                            {reward.target === "Ice" && <p style={modalStyle.tableStyle.table.thClan.text}>Ice</p>}
                            {reward.target === "Sand" && <p style={modalStyle.tableStyle.table.thClan.text}>Sand</p>}
                            {reward.target === "Cyber" && <p style={modalStyle.tableStyle.table.thClan.text}>Cyber</p>}
                            {reward.target === "Jungle" && <p style={modalStyle.tableStyle.table.thClan.text}>Jungle</p>}
                            {reward.target === "Wasteland" && <p style={modalStyle.tableStyle.table.thClan.text}>Wasteland</p>}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rewards.length > 0 && (
                      <tr>
                        <th style={modalStyle.tableStyle.table.th}>Battle Info</th>
                        {rewards.map((rewardD) => (
                          <td style={modalStyle.tableStyle.table.td}>
                            <p>Point: {rewardD.point}</p>
                            <p>Reward: {rewardD.reward}</p>
                            <p>Total Cat: {rewardD.total_cat}</p>
                            <p>Winner: {rewardD.clan}</p>
                          </td>
                        ))}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>

            <Modal show={rewardsInfoModalShow} onHide={handleRewardsInfoClose} size="lg" centered>
              <Modal.Header closeButton style={modalStyle.modalHeader} closeVariant="white">
                <Modal.Title>Rewards Details </Modal.Title>
              </Modal.Header>

              <Modal.Body style={modalStyle.modalBody}>
                <Table bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th style={modalStyle.tableStyle.table.thClan}>Clan</th>
                      <th style={modalStyle.tableStyle.table.thClan}>Total Cat</th>
                      <th style={modalStyle.tableStyle.table.thClan}>Total Point</th>
                      <th style={modalStyle.tableStyle.table.thClan}>Total Power</th>
                      <th style={modalStyle.tableStyle.table.thClan}>Total Reward</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardsInfo.length > 0 &&
                      rewardsInfo.map((reward, index) => (
                        <tr key={index}>
                          <th style={modalStyle.tableStyle.table.th}>
                            {reward.clan === "Volcano" && <img src={volcanoCard} alt="volcano-logo-modal" width="100"></img>}
                            {reward.clan === "Ice" && <img src={iceCard} alt="ice-logo-modal" width="100"></img>}
                            {reward.clan === "Sand" && <img src={sandCard} alt="sand-logo-modal" width="100"></img>}
                            {reward.clan === "Cyber" && <img src={cyberCard} alt="cyber-logo-modal" width="100"></img>}
                            {reward.clan === "Jungle" && <img src={jungleCard} alt="jungle-logo-modal" width="100"></img>}
                            {reward.clan === "Wasteland" && <img src={wastelandCard} alt="wasteland-logo-modal" width="100"></img>}

                            {reward.clan === "Volcano" && <p style={modalStyle.tableStyle.table.thClan.text}>Volcano</p>}
                            {reward.clan === "Ice" && <p style={modalStyle.tableStyle.table.thClan.text}>Ice</p>}
                            {reward.clan === "Sand" && <p style={modalStyle.tableStyle.table.thClan.text}>Sand</p>}
                            {reward.clan === "Cyber" && <p style={modalStyle.tableStyle.table.thClan.text}>Cyber</p>}
                            {reward.clan === "Jungle" && <p style={modalStyle.tableStyle.table.thClan.text}>Jungle</p>}
                            {reward.clan === "Wasteland" && <p style={modalStyle.tableStyle.table.thClan.text}>Wasteland</p>}
                          </th>
                          <td style={modalStyle.tableStyle.table.td}>
                            <p>{reward.total_cat}</p>
                          </td>
                          <td style={modalStyle.tableStyle.table.td}>
                            <p>{reward.total_point}</p>
                          </td>
                          <td style={modalStyle.tableStyle.table.td}>
                            <p>{reward.total_power}</p>
                          </td>
                          <td style={modalStyle.tableStyle.table.td}>
                            <p>{reward.total_reward}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>

            <ErrorModal showModal={showError} errorText={errorText} redirectHome={errorRedirect} parentCallback={handleCloseCallback} />
            <WeeklyRewardsModal showModal={showWeeklyReward} parentCallback={handleWeeklyRewardCallback} />
          </div>
        );
      } else {
        {
          return <Lottie options={lottieOptions} height="100%" width="100%" style={{ backgroundColor: "#1B2430" }} />;
        }
      }
    }
  }
};

export default GrumpyMap;
