import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import inventoryIcon from "../images/inventory-icon.svg";

export function WeeklyRewardsModal(props) {
  const { showModal } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    props.parentCallback();
  };
  const handleShow = () => setShow(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (showModal) {
      handleShow();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const modalStyle = {
    modalHeader: {
      background: "#1C1E32",
      borderRadius: "8px 8px 0px 0px; !important",
      borderBottom: "none",
      fontFamily: "'Ubuntu'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "24px",
      textAlign: "center",
      color: "#FFFFFF",
    },
    modalBody: {
      background: "#16182C",
      backdropFilter: "blur(20px)",
      height: "320px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      container: {
        background: "linear-gradient(130deg, rgba(229, 179, 9, 0.5) -25.21%, rgba(253, 0, 58, 0) 28.27%)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      rewardHead: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "34px",
        color: "#FFFFFF",
      },
      rewardText: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
      },
    },
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
        <Modal.Header closeButton style={modalStyle.modalHeader} closeVariant="white">
          <Modal.Title>Weekly Rewards Info</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid" style={modalStyle.modalBody}>
          <Container style={modalStyle.modalBody.container}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={12}>
                <img src={inventoryIcon} alt="Grumpy" className="reward-logo" />
              </Col>
            </Row>
            <Row style={{ marginBottom: "24px" }}>
              <Col xs={12}>
                <h3 className="reward-text" style={modalStyle.modalBody.rewardHead}>
                  Weekly Rewards
                </h3>
              </Col>
            </Row>
            <Row style={{ marginBottom: "48px" }}>
              <Col xs={12}>
                <p className="reward-text" style={modalStyle.modalBody.rewardText}>
                  Week: 2/16
                </p>
                <p className="reward-text" style={modalStyle.modalBody.rewardText}>
                  Weekly Rewards: 12.5 SOL + 12.500 Grumpy Token
                </p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
