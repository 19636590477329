import * as React from "react";
import { Routes, Route } from "react-router-dom";
// import Home from "./pages/Home";
import WarMap from "./pages/WarMap";
import MainPage from "./pages/Main";
import Claim from "./pages/Claim";
import SubmitWallet from "./pages/SubmitWallet";
import Parse from "parse";
import OneSignal from "react-onesignal";
import { useEffect } from "react";
import { fetchCurrentUser } from "./services/ParseService";
import Notifications from "react-notify-toast";

function App() {
  Parse.serverURL = "https://parseapi.back4app.com/";
  Parse.initialize("cZn3zG6SRQjUOXr9lpxW7SkPwFLQeYwKfgemnSyd", "bqM91V8K3LIshPWVEk9boP5aqvN98Mj8TMOrobBo");
  useEffect(() => {
    OneSignal.init({
      appId: "b76c5780-d517-4197-b20c-9a0cfdf1e589",
      allowLocalhostAsSecureOrigin: true,
    });
  }, []);
  if (fetchCurrentUser()) {
    console.log("User is logged in");
    OneSignal.sendTags({
      userId: fetchCurrentUser()!.id,
    });
    return (
      <div className="App">
        <Notifications />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/war" element={<WarMap />} />
          <Route path="/claim" element={<Claim />} />
          <Route path="/submit-wallet" element={<SubmitWallet />} />
        </Routes>
      </div>
    );
  } else {
    console.log("User is not logged in");
    return (
      <div className="App">
        <Notifications />
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </div>
    );
  }
}

export default App;
