import React, { useState, useEffect } from "react";
import backgroundHome from "../images/home-game-bg.png";
import grumpyLogoText from "../images/gr-home.svg";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { getClaimCat, getCats } from "../services/ParseService";
import { ClaimedModal } from "../components/ClaimedModal";
import generateCatButton from "../images/generate-cat-button.svg";
import submitWalletButton from "../images/submit-wallet-button.svg";

const mystyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundImage: `url(${backgroundHome})`,
    backgroundRepeat: "no-repeat",
    backgorundPosition: "center",
    backgroundSize: "cover",
  },
  mainText: {
    width: "100%",
    color: "white",
    fontSize: "17px",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Ubuntu, sans-serif",
    marginTop: "16px",
  },
  generateBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  generateButton: {
    width: "290px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#1E5D17",
    color: "white",
    cursor: "pointer",
  },
  cheetahCat: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "300px",
  },
  catImage: {
    width: "250px",
  },
  catClanMember: {
    backgroundColor: "#4338E8",
    color: "white",
    height: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  gameButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 35px",
    width: "300px",
    background: "#ffca53",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: "12px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  generateText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "35px",
    marginTop: "1rem",
    generateHead: {
      fontFamily: "'Ubuntu'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "37px",
      color: "#FFFFFF",
    },
    generateSubhead: {
      fontFamily: "'Ubuntu'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#81828D",
      marginTop: "16px",
      borderBottom: "0.8px solid #353749",
      paddingBottom: "16px",
    },
  },
};

const Claim = () => {
  const [loading, setLoading] = useState(false);
  const [currentCat, setCurrentCat] = useState([]);
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      setLoading(true);
      let cats = await getCats();
      setCurrentCat(cats.cats);
      setLoading(false);
    } catch (error) {
      navigate("/");
    }
  }, [navigate]);

  const claimCat = async () => {
    setLoading(true);
    try {
      const claimCat = await getClaimCat();
      let claimed = [];
      claimed.push(claimCat);
      setCurrentCat(claimed);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const submitWalletRedirect = async () => {
    navigate("/submit-wallet");
  };

  return (
    <div className="main-container" style={mystyle.mainContainer}>
      <img src={grumpyLogoText} alt="grumpy-logo" width="40%" />
      <span className="main_text" style={mystyle.mainText}>
        The 6 Cat Kingdoms are fighting for only 1 purpose. Will you be able to dominate the Grumpy island?
      </span>
      <div className="generate-text" style={mystyle.generateText}>
        <span className="generate-head" style={mystyle.generateText.generateHead}>
          Generate Cat
        </span>
        <span className="generate-subhead" style={mystyle.generateText.generateSubhead}>
          Generate the cat you used in the game
        </span>
      </div>
      <div className="generate-box" style={mystyle.generateBox}>
        {/* {currentCat.length > 0 && (
          <div>
            <div className="clan-member-name" style={mystyle.catClanMember}>
              {currentCat[0].get("clan")}
            </div>
            <img src={currentCat[0].get("image").url()} alt="cat" style={mystyle.catImage} />
          </div>
        )} */}
        {currentCat.length === 0 && (
          <div style={mystyle.generateBox}>
            <img src={generateCatButton} alt="play-game" style={{ width: "370px", cursor: "pointer" }} onClick={claimCat}></img>
            <img src={submitWalletButton} alt="play-game" style={{ width: "370px", cursor: "pointer" }} onClick={submitWalletRedirect}></img>
          </div>
        )}
      </div>
      {currentCat.length > 0 && <ClaimedModal showModal={true} currentCat={currentCat} />}
      {loading && <Loading />}
    </div>
  );
};

export default Claim;
