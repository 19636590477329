import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import grumpyLogoText from "../images/gr-home.svg";
import clanBg from "../images/clan-bg.png";
import powerIcon from "../images/power-icon.png";
import weatherIcon from "../images/weather-icon.png";
import landIcon from "../images/land-icon.png";
import playGameButton from "../images/play-game-button.png";

export function ClaimedModal(props) {
  const { showModal, currentCat } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (showModal) {
      handleShow();
    } else {
      handleClose();
    }
  }, [showModal]);

  const gameDirect = () => {
    navigate("/war");
  };

  const modalStyle = {
    modalHeader: {
      background: "#1C1E32",
      borderRadius: "8px 8px 0px 0px; !important",
      borderBottom: "none",
    },
    modalBody: {
      background: "#16182C",
      backdropFilter: "blur(20px)",
      catImage: {
        width: "100%",
      },
      catClan: {
        clanText: {
          fontFamily: "'Ubuntu'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "20px",
          lineHeight: "23px",
          textAlign: "center",
          color: "#FFFFFF",
          position: "absolute",
          left: "45px",
          marginTop: "0.7rem",
          width: "165px",
        },
      },
      catParameters: {
        catPower: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px 5px",
          powerIcon: {},
          powerInfo: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "1rem",
            width: "100%",
            powerText: {
              fontFamily: "'Ubuntu'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#B3B4BB",
            },
            powerValue: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              valueSpan: {
                fontFamily: "'Ubuntu'",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
                marginLeft: "12px",
              },
              valueProgress: {
                width: "100%",
                height: "10px",
              },
            },
          },
        },
        catWeather: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px 5px",
          weatherIcon: {},
          weatherInfo: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "1rem",
            width: "100%",
            weatherText: {
              fontFamily: "'Ubuntu'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#B3B4BB",
            },
            weatherValue: {
              fontFamily: "'Ubuntu'",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#FFFFFF",
              textTransform: "uppercase",
            },
          },
        },
        catLand: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px 5px",
          landIcon: {},
          landInfo: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "1rem",
            width: "100%",
            landText: {
              fontFamily: "'Ubuntu'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#B3B4BB",
            },
            landValue: {
              fontFamily: "'Ubuntu'",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#FFFFFF",
              textTransform: "uppercase",
            },
          },
        },
        connectWalletArea: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          landText: {
            fontFamily: "'Ubuntu'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#B3B4BB",
            cursor: "pointer",
          },
        },
      },
    },
  };

  const handleWalletConnect = () => {
    navigate("/submit-wallet");
  };

  return (
    <>
      <Modal show={show} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
        <Modal.Header style={modalStyle.modalHeader}>
          <img src={grumpyLogoText} alt="grumpy-logo" width="30%" />
        </Modal.Header>
        <Modal.Body className="show-grid" style={modalStyle.modalBody}>
          <Container>
            <Row>
              <Col xs={8} md={6}>
                <img src={currentCat[0].get("image").url()} alt="cat" style={modalStyle.modalBody.catImage} />
                <div className="clan" style={modalStyle.modalBody.catClan}>
                  <img src={clanBg} alt="clan-bg-side" />
                  <span style={modalStyle.modalBody.catClan.clanText}>{currentCat[0].get("clan")}</span>
                </div>
              </Col>
              <Col xs={10} md={6}>
                <div className="cat-parameters" style={modalStyle.modalBody.catParameters}>
                  <div className="cat-power" style={modalStyle.modalBody.catParameters.catPower}>
                    <div className="power-icon" style={modalStyle.modalBody.catParameters.catPower.powerIcon}>
                      <img src={powerIcon} alt="power-icon" />
                    </div>
                    <div className="power-info" style={modalStyle.modalBody.catParameters.catPower.powerInfo}>
                      <span className="power-info-text" style={modalStyle.modalBody.catParameters.catPower.powerInfo.powerText}>
                        Power
                      </span>
                      <span className="power-info-value" style={modalStyle.modalBody.catParameters.catPower.powerInfo.powerValue}>
                        <ProgressBar
                          now={currentCat[0].get("power")}
                          min={0}
                          max={20}
                          style={modalStyle.modalBody.catParameters.catPower.powerInfo.powerValue.valueProgress}
                        />

                        <span className="value" style={modalStyle.modalBody.catParameters.catPower.powerInfo.powerValue.valueSpan}>
                          {currentCat[0].get("power")}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="cat-weather" style={modalStyle.modalBody.catParameters.catWeather}>
                    <div className="weather-icon" style={modalStyle.modalBody.catParameters.catWeather.weatherIcon}>
                      <img src={weatherIcon} alt="weather-icon" />
                    </div>
                    <div className="weather-info" style={modalStyle.modalBody.catParameters.catWeather.weatherInfo}>
                      <span className="weather-info-text" style={modalStyle.modalBody.catParameters.catWeather.weatherInfo.weatherText}>
                        Weather
                      </span>
                      <span className="weather-info-value" style={modalStyle.modalBody.catParameters.catWeather.weatherInfo.weatherValue}>
                        {currentCat[0].get("weather")}
                      </span>
                    </div>
                  </div>
                  <div className="cat-land" style={modalStyle.modalBody.catParameters.catLand}>
                    <div className="land-icon" style={modalStyle.modalBody.catParameters.catLand.landIcon}>
                      <img src={landIcon} alt="land-icon" />
                    </div>
                    <div className="land-info" style={modalStyle.modalBody.catParameters.catLand.landInfo}>
                      <span className="land-info-text" style={modalStyle.modalBody.catParameters.catLand.landInfo.landText}>
                        Land
                      </span>
                      <span className="land-info-value" style={modalStyle.modalBody.catParameters.catLand.landInfo.landValue}>
                        {currentCat[0].get("ground")}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} style={{ marginTop: "2rem" }}>
                <img src={playGameButton} alt="play-game" style={{ width: "100%", cursor: "pointer" }} onClick={gameDirect}></img>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} style={modalStyle.modalBody.catParameters.connectWalletArea}>
                <span style={modalStyle.modalBody.catParameters.connectWalletArea.landText} onClick={handleWalletConnect}>
                  Connect your phantom wallet (for WL)
                </span>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
