import React, { useState, useEffect } from "react";
import Parse from "parse";
import backgroundHome from "../images/home-game-bg.png";
import grumpyLogoText from "../images/gr-home.svg";
import loginButton from "../images/login-button.svg";
import { generateAuthURL, authDiscord, fetchDiscordUser, fetchCurrentUser } from "../services/ParseService";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { ErrorModal } from "../components/ErrorModal";

const MainPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //Error handling
  const [errorText, setErrorText] = useState("Something went wrong. Let’s try one more again");
  const [showError, setShowError] = useState(false);
  const [errorRedirect, setErrorRedirect] = useState(false);

  const mystyle = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      backgroundImage: `url(${backgroundHome})`,
      backgroundRepeat: "no-repeat",
      backgorundPosition: "center",
      backgroundSize: "cover",
    },
    mainText: {
      width: "100%",
      color: "white",
      fontSize: "17px",
      fontWeight: "bold",
      textAlign: "center",
      fontFamily: "Ubuntu, sans-serif",
      marginTop: "16px",
    },
    discordLogin: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "35px",
      marginTop: "5rem",
      loginHead: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "32px",
        lineHeight: "37px",
        color: "#FFFFFF",
      },
      loginSubHead: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#81828D",
        marginTop: "16px",
        borderBottom: "0.8px solid #353749",
        paddingBottom: "16px",
      },
    },
    cheetahCat: {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "300px",
    },
    redirectSide: {
      width: "55%",
      textAlign: "center",
    },
    redirectProgress: {
      marginBottom: "2rem",
    },
  };

  useEffect(() => {
    let code = new URL(window.location.href).searchParams.get("code");
    if (code != null) {
      authDiscord(code)
        .then(async (result) => {
          Parse.User.become(result.attributes.sessionToken).then(async () => {
            fetchDiscordUser()
              .then((result) => {
                window.location.href = "/";
              })
              .catch((err) => {
                setErrorRedirect(true);
                setErrorText(err.message);
                setShowError(true);
              });
          });
        })
        .catch((err) => {
          throw err;
        });
    }
    if (fetchCurrentUser() != null) {
      navigate("/claim", { replace: true });
    }
    // setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1));
  }, [navigate]);

  const getAuthUrl = async () => {
    setLoading(true);
    const authURL = await generateAuthURL();
    window.location.href = authURL;
    setLoading(false);
  };

  const handleCloseCallback = () => {
    setShowError(false);
  };

  return (
    <div className="main-container" style={mystyle.mainContainer}>
      <img src={grumpyLogoText} alt="grumpy-logo" width="40%" />
      <span className="main_text" style={mystyle.mainText}>
        The 6 Cat Kingdoms are fighting for only 1 purpose. Will you be able to dominate the Grumpy island?
      </span>
      <div className="discord-login" style={mystyle.discordLogin}>
        <span className="login-head" style={mystyle.discordLogin.loginHead}>
          SIGN TO GRUMPY CATS
        </span>
        <span className="login-subhead" style={mystyle.discordLogin.loginSubHead}>
          Log in with your Discord account
        </span>
        <img src={loginButton} alt="login-button" onClick={getAuthUrl} style={{ cursor: "pointer" }} />
      </div>
      {loading && <Loading />}

      <ErrorModal showModal={showError} errorText={errorText} redirectHome={errorRedirect} parentCallback={handleCloseCallback} />
    </div>
  );
};

export default MainPage;
