import grumpyLogoText from "../images/gr-home.svg";
// import leaderboardIcon from "../images/leaderboard-icon.svg";
// import marketplaceIcon from "../images/marketplace-icon.svg";
import inventoryIcon from "../images/inventory-icon.svg";
import tutorialIcon from "../images/tutorial-icon.svg";
import snowIcon from "../images/snow-icon.svg";

import volcanoCard from "../images/Card/volcano.svg";
import iceCard from "../images/Card/ice.svg";
import sandCard from "../images/Card/desert.svg";
import cyberCard from "../images/Card/cyber.svg";
import jungleCard from "../images/Card/jungle.svg";
import wastelandCard from "../images/Card/wasteland.svg";

const headerStyle = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "95px",
    backgroundColor: "rgb(42, 37, 50)",
    paddingRight: "70px",
    paddingLeft: "70px",
    position: "fixed",
    width: "100%",
    zIndex: "2",
    background: "rgba(3, 5, 27, 0.3)",
    backdropFilter: "blur(10px)",
    leftSide: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "33%",
      leftItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textStyle: {
          fontSize: "14px",
          color: "#ffffff",
          marginTop: "8px",
        },
        weather: { fontFamily: "'Ubuntu'", fontStyle: "normal", fontWeight: "400", fontSize: "12px", lineHeight: "18px", color: "#B3B4BB" },
        weatherText: {
          fontFamily: "'Ubuntu'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "capitalize",
          marginTop: "8px",
        },
      },
    },
    middleSide: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "33%",
      leftItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingRight: "50px",
        textStyle: {
          fontSize: "14px",
          color: "#ffffff",
          marginTop: "8px",
        },
        weather: {
          color: "white",
        },
        weatherText: {
          background: "#49415C",
          color: "white",
          width: "85px",
          borderRadius: "10px",
          marginTop: "12px",
          height: "35px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "capitalize",
        },
      },
    },
    rightSide: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "33%",
      rightItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "24px",
        cursor: "pointer",
        textStyle: {
          fontSize: "14px",
          color: "#ffffff",
          marginTop: "8px",
        },
        landStatus: {
          color: "white",
        },
        landStatusText: {
          background: "#C3C32A",
          color: "white",
          width: "85px",
          textAlign: "center",
          borderRadius: "10px",
          marginTop: "12px",
          textTransform: "capitalize",
        },
      },
    },
  },
};

const GrumpyHeader = (props) => {
  // useEffect(() => {
  //   console.log(props);
  // });

  const clickTutorial = () => {
    props.clickTutorial();
  };

  const clickReward = () => {
    props.clickReward();
  };

  return (
    <div style={headerStyle.headerContainer}>
      <div style={headerStyle.headerContainer.leftSide}>
        <div style={headerStyle.headerContainer.leftSide.leftItem}>
          <img src={grumpyLogoText} alt="Grumpy - Header Logo" width={140} />
        </div>
      </div>
      <div style={headerStyle.headerContainer.middleSide}>
        <div style={headerStyle.headerContainer.middleSide.leftItem}>
          <span style={headerStyle.headerContainer.leftSide.leftItem.weather}>{props.weather && "Weather"}</span>
          <span style={headerStyle.headerContainer.leftSide.leftItem.weatherText}>
            {props.weather}
            {props.weather && <img src={snowIcon} alt="Weather" style={{ marginLeft: "8px", width: "30px" }} />}
          </span>
        </div>
        <div style={headerStyle.headerContainer.middleSide.leftItem}>
          <span style={headerStyle.headerContainer.leftSide.leftItem.weather}>{props.landStatus && "Land Status"}</span>
          <span style={headerStyle.headerContainer.leftSide.leftItem.weatherText}>
            {props.landStatus}
            {props.landStatus === "volcano" && <img src={volcanoCard} alt="Volcano" style={{ marginLeft: "8px", width: "30px" }} />}
            {props.landStatus === "ice" && <img src={iceCard} alt="Ice" style={{ marginLeft: "8px", width: "30px" }} />}
            {props.landStatus === "desert" && <img src={sandCard} alt="Desert" style={{ marginLeft: "8px", width: "30px" }} />}
            {props.landStatus === "cyber" && <img src={cyberCard} alt="Cyber" style={{ marginLeft: "8px", width: "30px" }} />}
            {props.landStatus === "jungle" && <img src={jungleCard} alt="Jungle" style={{ marginLeft: "8px", width: "30px" }} />}
            {props.landStatus === "wasteland" && <img src={wastelandCard} alt="Wasteland" style={{ marginLeft: "8px", width: "30px" }} />}
          </span>
        </div>
      </div>
      <div style={headerStyle.headerContainer.rightSide}>
        {/* <div style={headerStyle.headerContainer.rightSide.rightItem}>
          <img src={leaderboardIcon} alt="Leaderboard" width="50" />
        </div>
        <div style={headerStyle.headerContainer.rightSide.rightItem}>
          <img src={marketplaceIcon} alt="Marketplace" width="40" />
        </div> */}
        <div style={headerStyle.headerContainer.rightSide.rightItem} onClick={clickReward}>
          <img src={inventoryIcon} alt="Inventory" width="40" />
          {/* <span style={headerStyle.headerContainer.rightSide.rightItem.textStyle}>Inventory</span> */}
        </div>
        <div style={headerStyle.headerContainer.rightSide.rightItem} onClick={clickTutorial}>
          <img src={tutorialIcon} alt="Tutorial" width="40" />
          {/* <span style={headerStyle.headerContainer.rightSide.rightItem.textStyle}>Tutorial</span> */}
        </div>
      </div>
    </div>
  );
};

export default GrumpyHeader;
