import Parse from "parse";

export const fetchCurrentUser = () => {
  return Parse.User.current();
};

export const resetPassword = async (email) => {
  return await Parse.User.requestPasswordReset(email);
};

export const generateAuthURL = async () => {
  return await Parse.Cloud.run("generateAuthUrl")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const authDiscord = async (code) => {
  return await Parse.Cloud.run("authdiscord", { code: code })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchDiscordUser = async () => {
  return await Parse.Cloud.run("fetchdiscorduser")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCats = async () => {
  return await Parse.Cloud.run("getCats")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getClaimCat = async () => {
  return await Parse.Cloud.run("claimCat")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getBattleGround = async () => {
  return await Parse.Cloud.run("GetBattleGround")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getBattleGroundStatistic = async (battleground_id) => {
  return await Parse.Cloud.run("GetBattleGroundStatistic", { battleground_id })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const simulateSpot = async (target, battleground_id, cat_id) => {
  return await Parse.Cloud.run("SimulateSpot", {
    target,
    battleground_id,
    cat_id,
  })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const joinBattleGround = async (battleground_id, target, cat_id) => {
  return await Parse.Cloud.run("JoinBattleGround", { battleground_id, target, cat_id })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const setUserPhantomInfo = async (phantomAddress, phantomGrumpyTokenInfo, phantomSolanaBalance, phantomNfts) => {
  const currentUser = await Parse.User.current();
  currentUser.set("phantomAddress", phantomAddress);
  currentUser.set("phantomGrumpyTokenInfo", phantomGrumpyTokenInfo);
  currentUser.set("phantomSolanaBalance", phantomSolanaBalance);
  currentUser.set("phantomNfts", phantomNfts);
  return await currentUser.save();
};

export const getCurrentUserInfo = async () => {
  const currentUser = await Parse.User.current();

  const query = new Parse.Query("_User");
  query.equalTo("objectId", currentUser.id);
  return await query.first();
};

export const getRewardsInfo = async (battleground_id) => {
  const query = new Parse.Query("Winner");
  query.equalTo("battleground_id", battleground_id);
  return await query.find();
};
